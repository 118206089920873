import { FormType } from '@/constant/form';
import { OPERATE_TYPE } from '../constant';

export default [
  {
    label: '原作业方式：',
    prop: 'oldOperateType',
    colAttrs: {
      span: 24,
    },
  },
  {
    label: '更改为：',
    prop: 'operateType',
    colAttrs: {
      span: 24,
    },
    component: FormType.SELECT,
    subComponent: 'el-option',
    options: OPERATE_TYPE,
  },
];
