<template>
  <nh-dialog
    v-model="dialogVisible"
    title="添加明细"
    class="custom-dialog_1250"
    @close="handleBeforeClose"
  >
    <PlForm
      v-model="form"
      mode="normal"
      :fields="formFileds"
      :form-props="{rules:rules, labelWidth: '150px'}"
      :collapse="false"
      submit-btn-text="确 定"
      reset-btn-text="取 消"
      @submit="handleSubmit"
      @reset="handleClose"
    >
      <template #shipperId>
        <nh-shipper-selector
          v-model:shipperId="form.shipperId"
          @change="handleShipperChange"
        />
      </template>
      <template #shipperGoodsCode>
        <el-select
          v-model="form.shipperGoodsCode"
          filterable
          clearable
          @change="handleGoodsChange"
        >
          <el-option
            v-for="item in goodsList"
            :key="item.shipperGoodsCode"
            :label="item.shipperGoodsCode"
            :value="item.shipperGoodsCode"
          />
        </el-select>
      </template>
      <template #locationCode>
        <el-select
          v-model="form.locationCode"
          filterable
          clearable
          @change="handleLocationChange"
        >
          <el-option
            v-for="item in locationList"
            :key="item.storageLocationCode"
            :label="item.storageLocationCode"
            :value="item.storageLocationCode"
          />
        </el-select>
      </template>
      <template #batchNo>
        <el-select
          v-model="form.batchNo"
          filterable
          clearable
          @change="handleBatchNoChange"
        >
          <el-option
            v-for="item in batchNoList"
            :key="item"
            :label="item"
            :value="item"
          />
        </el-select>
      </template>
      <template #availableAmount>
        <span>{{ form.availableAmount }}</span>
      </template>
    </PlForm>
  </nh-dialog>
</template>

<script>
import loadingMixin from '@thales/loading';
import { containerStockPage } from '@/views/in-library-management/inventory-management/container-inventory-query/api';
import {
  getGoodsList, getLocationList, getBatchNoList,
} from '../../api';
import formFileds, { selectProp } from './fileds';

export default {
  mixins: [loadingMixin],
  emits: ['submit'],
  data() {
    return {
      dialogVisible: false,
      formFileds,
      form: {
        shipperId: null,
        shipperGoodsCode: null,
        locationCode: null,
        batchNo: null,
        goodsId: null,
        storehouseId: null,
      },
      goodsList: [],
      locationList: [],
      batchNoList: [],
      rules: {
        shipperId: [
          { required: true, message: '请选择货主', trigger: 'change' },
        ],
        shipperGoodsCode: [
          { required: true, message: '请选择货主货品编码', trigger: 'change' },
        ],
        locationCode: [
          { required: true, message: '请选择库位', trigger: 'change' },
        ],
        batchNo: [
          { required: true, message: '请选择批次号', trigger: 'change' },
        ],
      },
    };
  },
  methods: {
    handleClose() {
      this.dialogVisible = false;
    },
    init() {
      this.dialogVisible = true;
    },
    handleShipperChange() {
      this.resetGoodsList();
    },
    async resetGoodsList() {
      this.form.shipperGoodsCode = '';
      this.form.goodsId = '';
      this.form.locationCode = '';
      this.form.storehouseId = '';
      this.form.batchNo = '';
      this.goodsList = [];
      this.locationList = [];
      this.batchNoList = [];
      this.setFormDetail();
      if (this.form.shipperId) {
        const data = {
          shipperId: this.form.shipperId,
        };
        this.goodsList = await getGoodsList(data);
      } else {
        this.goodsList = [];
      }
    },
    handleGoodsChange() {
      const currentGoods = this.goodsList.find((goods) => goods.shipperGoodsCode
      === this.form.shipperGoodsCode);
      this.form.goodsId = currentGoods?.id || '';
      this.resetLocation();
    },
    async resetLocation() {
      this.form.locationCode = '';
      this.form.storehouseId = '';
      this.form.batchNo = '';
      this.locationList = [];
      this.batchNoList = [];
      this.setFormDetail();
      if (this.form.goodsId) {
        const data = {
          shipperId: this.form.shipperId,
          goodsId: this.form.goodsId,
        };
        this.locationList = await getLocationList(data);
      } else {
        this.locationList = [];
      }
    },
    handleLocationChange() {
      const currentGoods = this.locationList.find((item) => item.storageLocationCode
      === this.form.locationCode);
      this.form.storehouseId = currentGoods?.id || '';
      this.resetBatchNo();
    },
    async resetBatchNo() {
      this.form.batchNo = '';
      this.batchNoList = [];
      this.setFormDetail();
      const data = {
        shipperId: this.form.shipperId,
        goodsId: this.form.goodsId,
        storehouseId: this.form.storehouseId,
      };
      this.batchNoList = await getBatchNoList(data);
    },
    async handleBatchNoChange() {
      const data = {
        shipperId: this.form.shipperId,
        goodsCode: this.form.shipperGoodsCode,
        locationCode: this.form.locationCode,
        batchNo: this.form.batchNo,
      };
      const pagination = {
        page: 1,
        size: 10,
      };
      const result = await containerStockPage(pagination, data);
      const goodsDetail = result.records[0] || {};
      this.setFormDetail(goodsDetail);
    },
    setFormDetail(goods) {
      const selectData = selectProp.reduce((total, prop) => ({
        ...total,
        [prop]: this.form[prop],
      }), {});
      if (goods) {
        this.form = { ...goods, ...selectData };
      } else {
        this.form = { ...selectData };
      }
    },
    async handleSubmit() {
      if (Object.keys(this.form).length !== selectProp.length) {
        this.$emit('submit', { ...this.form });
        this.handleClose();
      } else {
        this.message({ type: 'warning', message: '当前筛选无可移库货品，请重新筛选！' });
      }
    },
    handleBeforeClose() {
      this.form = selectProp.reduce((total, prop) => ({
        ...total,
        [prop]: null,
      }), {});
      this.goodsList = [];
      this.locationList = [];
      this.batchNoList = [];
      this.setFormDetail();
    },
  },
};
</script>
