import { DateTime } from '@/constant/tableConfig';
import {
  TEMPERATURE_LAYER, GOODS_STATUS, SUBTASK_STATUS, TASK_TYPES_CN,
} from '../constant';

function filterLabel(val, arr) {
  const filterArr = arr.find((item) => item.value === val);
  return filterArr ? filterArr.label : '';
}
export const GOODSDETAILSTABLECOLUMN = (GOODS_QUALITY) => [{
  label: '序号',
  type: 'index',
  width: 50,
}, {
  label: '货品编码',
  prop: 'shipperGoodsCode',
  minWidth: 180,
}, {
  label: '货品名称',
  prop: 'goodsName',
  minWidth: 100,
}, {
  label: '货主',
  prop: 'shipperName',
  minWidth: 100,
}, {
  label: '温层',
  prop: 'temperatureLayer',
  minWidth: 80,
  formatter: ({ temperatureLayer }) => TEMPERATURE_LAYER[temperatureLayer],
}, {
  label: '库位',
  prop: 'sourceLocationCode',
  minWidth: 80,
}, {
  label: '批次号',
  prop: 'batchNo',
  minWidth: 120,
}, {
  label: '容器号',
  prop: 'sourceContainerCode',
  minWidth: 100,
}, {
  label: '货品质量',
  prop: 'goodsQuality',
  minWidth: 100,
  formatter: ({ goodsQuality }) => filterLabel(goodsQuality, GOODS_QUALITY),
}, {
  label: '移库数量',
  prop: 'transferStockAmount',
  minWidth: 100,
}, {
  label: '状态',
  prop: 'status',
  minWidth: 100,
  formatter: ({ status }) => GOODS_STATUS[status],
},
];

export const TASKDETAILSTABLECOLUMN = [{
  label: '序号',
  type: 'index',
  width: 50,
}, {
  label: '货品编码',
  prop: 'shipperGoodsCode',
  minWidth: 180,
}, {
  label: '货品名称',
  prop: 'goodsName',
  minWidth: 100,
}, {
  label: '货主',
  prop: 'shipperName',
  minWidth: 100,
}, {
  label: '作业状态',
  prop: 'subtaskStatus',
  minWidth: 80,
  formatter: ({ subtaskStatus }) => SUBTASK_STATUS[subtaskStatus],
}, {
  label: '作业类型',
  prop: 'taskType',
  minWidth: 80,
  formatter: ({ taskType }) => TASK_TYPES_CN[taskType],
}, {
  label: '批次号',
  prop: 'batchNo',
  minWidth: 80,
}, {
  label: '原库位',
  prop: 'unloadStorehouseCode',
  minWidth: 120,
}, {
  label: '原数量',
  prop: 'unAmountStr',
  minWidth: 100,
}, {
  label: '目标库位',
  prop: 'onloadStorehouseCode',
  minWidth: 100,
}, {
  label: '任务数量',
  prop: 'onloadAmountStr',
  minWidth: 100,
  formatter: (row) => row.amountStr || row.onloadAmountStr,
}, {
  label: '目标容器',
  prop: 'containerCode',
  minWidth: 100,
}, {
  label: '作业人',
  prop: 'operatorStaffName',
  minWidth: 100,
}, {
  label: '作业时间',
  prop: 'operationTime',
  ...DateTime,
},
];

export const DETAIL_TABS = {
  GOODS_DETAILED: 'GOODS_DETAILED', // 货品明细
  TASK_DETAILS: 'TASK_DETAILS', // 任务详情
};
