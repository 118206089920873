import { wmsPlusHttp } from '@/utils/http/index';

/**
 * @description:移库单 分页查询
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/15799
 */
export function stockTransferOrderPage(params, data) {
  return wmsPlusHttp.post('/warehouse_management_system/transfer_order/page', data, {
    params,
  });
}

/**
 * @description:移库单 修改
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/15797
 */
export function stockTransferOrderEdit(data) {
  return wmsPlusHttp.post('/warehouse_management_system/transfer_order/edit', data);
}

/**
 * @description:移库单 根据ID删除
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/15801
 */
export function stockTransferOrderDelete(data) {
  return wmsPlusHttp.post('/warehouse_management_system/transfer_order/delete', data);
}

/**
 * @description:更改作业方式
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/15803
 */
export function operateTypeChange(data) {
  return wmsPlusHttp.post('/warehouse_management_system/transfer_order/operate_type/change', data);
}
/**
 * @description:移库单提交
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/15805
 */
export function operateTypeRefer(data) {
  return wmsPlusHttp.post('/warehouse_management_system/transfer_order/submit', data);
}
/**
 * @description:移库单明细列表
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/15853
 */
export function stockTransferOrderDetail(data) {
  return wmsPlusHttp.post('/warehouse_management_system/transfer_order_detail/list', data);
}
/**
 * @description:移库单关闭
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/15875
 */
export function stockTransferOrderClose(data) {
  return wmsPlusHttp.post('/warehouse_management_system/transfer_order/close', data);
}

/**
 * @description:移库单确认
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/15877
 */
export function stockTransferOrderConfirm(data) {
  return wmsPlusHttp.post('/warehouse_management_system/transfer_order/confirm', data);
}

/**
 * @description:查询可用库存货品
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/15867
 */
export function getGoodsList(data) {
  return wmsPlusHttp.post('/warehouse_management_system/stock/goods/available', data);
}

/**
 * @description:查询可用库存库位
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/15869
 */
export function getLocationList(data) {
  return wmsPlusHttp.post('/warehouse_management_system/stock/storehouse/available', data);
}

/**
 * @description:查询可用库存批次
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/15871
 */
export function getBatchNoList(data) {
  return wmsPlusHttp.post('/warehouse_management_system/stock/batch_no/available', data);
}

/**
 * @description:移库单货品明细
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/15941
 */
export function getGoodsDetails(data) {
  return wmsPlusHttp.post('/warehouse_management_system/transfer_order_detail/goods_details', data);
}

/**
 * @description:子任务详情 分页查询
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/11780
 */
export function subtasksPage(params, data) {
  return wmsPlusHttp.post('/warehouse_management_system/subtasks/page', data, {
    params,
  });
}

/**
 * @description: 下载模板
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/12508
 *
 */
export function downLoadTemplate(data) {
  return wmsPlusHttp.download('/warehouse_management_system/file/template/download', data, { fileName: '移库单导入模板', extension: 'xlsx' });
}

/**
 * @description: 移库单导入校验
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/16419
 */

export function transferOrderImportCheck(data, params) {
  return wmsPlusHttp.post('/warehouse_management_system/transfer_order/import/check', data, {
    params,
  });
}

/**
 * @description: 一键生成移库单
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/15795
 */

export function transferOrderGenerate(data) {
  return wmsPlusHttp.post('/warehouse_management_system/transfer_order/generate', data);
}
