<template>
  <nh-drawer
    v-model="drawerVisible"
    :before-close="handleClose"
    destroy-on-close
    title="移库单详情"
    size="1111px"
    :track="{
      name_zh: `在库管理/移库管理/抽屉-详情`,
      trackName: $route.path
    }"
  >
    <div v-loading="loading.init" class="detail-content">
      <nh-title-text title="主信息" />
      <div class="basic-info">
        <nh-dialog-form label-width="80px" label-position="left">
          <nh-dialog-form-item :span="8" label="移库单号:">
            {{ basicInfoData.transferOrderNo }}
          </nh-dialog-form-item>
          <!-- <nh-dialog-form-item :span="8" label="货主名称:">
            {{ basicInfoData.shipperName }}
          </nh-dialog-form-item> -->
          <nh-dialog-form-item :span="8" label="移库单类型:">
            {{ filterLabel(basicInfoData.transferType,TRANSFER_TYPE) }}
          </nh-dialog-form-item>
          <nh-dialog-form-item :span="8" label="创建时间:">
            {{ Moment.format(basicInfoData.createTime) }}
          </nh-dialog-form-item>
          <nh-dialog-form-item :span="8" label="创建人:">
            {{ basicInfoData.createUserName }}
          </nh-dialog-form-item>
          <nh-dialog-form-item :span="8" label="完成时间:">
            {{ Moment.format(basicInfoData.completedTime) }}
          </nh-dialog-form-item>
        </nh-dialog-form>
      </div>
      <div class="detail-tabs-box">
        <el-tabs v-model="activeName">
          <el-tab-pane label="货品明细" :name="DETAIL_TABS.GOODS_DETAILED">
            <PlTable
              :loading="loading.getGoodsDetails"
              :data="goodsDetails"
              :columns="GOODSDETAILSTABLECOLUMN(GOODS_QUALITY)"
            />
          </el-tab-pane>
          <el-tab-pane label="移库任务详情" :name="DETAIL_TABS.TASK_DETAILS">
            <PlTable
              v-model:pagination="pagination"
              :loading="loading.getSubtasksPage"
              :data="taskDetails"
              :columns="TASKDETAILSTABLECOLUMN"
              :span-method="arraySpanMethod"
              border
              @update:pagination="handlePaginationChange"
            />
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </nh-drawer>
</template>

<script>
import loadingMixin from '@thales/loading';
import Moment from '@/utils/moment';
import { DETAIL_TABS, GOODSDETAILSTABLECOLUMN, TASKDETAILSTABLECOLUMN } from './fileds';
import { getGoodsDetails, subtasksPage } from '../api';
import { TRANSFER_TYPE, TASK_TYPES } from '../constant';

export default {
  name: 'LibraryTransferDetails',
  components: {
  },
  mixins: [loadingMixin],
  props: {
    idData: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['handleClickStore'],
  data() {
    return {
      drawerVisible: false,
      Moment,
      DETAIL_TABS,
      TRANSFER_TYPE,
      TASK_TYPES,
      GOODSDETAILSTABLECOLUMN,
      TASKDETAILSTABLECOLUMN,
      loading: {
        init: false,
        getGoodsDetails: false,
        getSubtasksPage: false,
      },
      activeName: DETAIL_TABS.GOODS_DETAILED,
      basicInfoData: {},
      goodsDetails: [],
      taskDetails: [],
    };
  },
  computed: {
    GOODS_QUALITY() {
      return this.$dictionaryFiledMap.GOODS_QUALITY || [];
    },
  },
  watch: {
    idData: {
      deep: true,
      handler(val) {
        if (val.num) this.open(val.row);
      },
    },
  },
  methods: {
    open({ id }) {
      this.transferOrderId = id;
      this.pagination.page = 1;
      this.drawerVisible = true;
      this.init();
    },
    async init() {
      await this.getGoodsDetails();
      await this.getSubtasksPage();
    },
    async getGoodsDetails() {
      const { transferOrderId } = this;
      const result = await getGoodsDetails({ transferOrderId });
      this.basicInfoData = result.transferOrder || {};
      this.goodsDetails = result.goodsDetails || [];
    },
    async getSubtasksPage() {
      const data = this.buildParams();
      const pagination = {
        page: this.pagination.page,
        size: this.pagination.size,
      };
      const result = await subtasksPage(pagination, data);
      const tableData = result.records || [];
      const newArr = [];
      let mergeNum = 0;
      tableData.forEach((item) => {
        if (item.subtaskRecords.length > 0) {
          item.subtaskRecords.forEach((it) => {
            newArr.push({
              ...item,
              ...it,
              lengthNum: item.subtaskRecords.length,
              mergeNum,
              unAmountStr: item.amountStr,
              onloadAmountStr: it.amountStr || it.onloadAmountStr,
            });
          });
          mergeNum += item.subtaskRecords.length;
        } else {
          newArr.push({
            ...item,
            lengthNum: 1,
            mergeNum,
            unAmountStr: item.amountStr,
          });
          mergeNum += 1;
        }
      });
      newArr[0].mergeNum = 0;
      this.taskDetails = newArr;
      this.pagination.total = result.total;
    },
    buildParams() {
      const { transferOrderId } = this;
      const data = {
        bizId: transferOrderId,
        taskTypes: [TASK_TYPES.TRANSFER_DOWN, TASK_TYPES.TRANSFER_UP],
      };
      return data;
    },
    filterLabel(val, arr) {
      const filertArr = arr && arr.find((item) => item.value === val);
      return filertArr && filertArr.label;
    },
    handlePaginationChange() {
      this.getSubtasksPage();
    },
    arraySpanMethod({ row, rowIndex, columnIndex }) {
      if (columnIndex < 8) {
        if (rowIndex === row.mergeNum) {
          return [row.lengthNum, 1];
        }
        return [0, 0];
      }
      return [1, 1];
    },
    handleClose() {
      this.basicInfoData = {};
      this.goodsDetails = [];
      this.taskDetails = [];
      this.$emit('handleClickStore', true);
      this.drawerVisible = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.detail-content {
  padding: 24px;

  .basic-info {
    padding: 24px;
  }
}
</style>
