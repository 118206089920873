<template>
  <nh-dialog
    v-model="dialogVisible"
    title="一键移库"
    custom-class="custom-dialog_1200"
    :track="{
      name_zh: `在库管理/移库管理/抽屉-编辑`,
      trackName: $route.path
    }"
    @close="handleBeforeClose"
  >
    <nh-pro-table-header>
      <template #headerRight>
        <el-button
          type="primary"
          size="small"
          plain
          @click="handleAdd"
        >
          添加
        </el-button>
      </template>
    </nh-pro-table-header>
    <PlTable
      :data="tableData"
      :columns="TABLECOLUMN"
    >
      <template #targetLocationCode="{row}">
        <nh-remote-select
          v-model="row.targetLocationCode"
          :remote-method="(query)=>{remoteLocation(query, row)}"
          :loading="loading.remoteLocation"
        >
          <el-option
            v-for="item in row.storehouseList"
            :key="item.value"
            :label="item.value"
            :value="item.value"
          />
        </nh-remote-select>
      </template>
      <template #targetContainerCode="{row}">
        <nh-remote-select
          v-model="row.targetContainerCode"
          :remote-method="(query)=>{remoteContainerCode(query,row)}"
          :loading="loading.remoteContainerCode"
        >
          <el-option
            v-for="item in row.containerCodeList"
            :key="item.value"
            :label="item.value"
            :value="item.value"
          />
        </nh-remote-select>
      </template>
      <template #transferAmount="{row}">
        <el-input-number
          v-model="row.bigUnitTransferAmount"
          :min="0"
          size="small"
          class="amount-box"
          step-strictly
          :step="row.bigUnit === row.smallUnit?
            STEPS_NUM.the_same:STEPS_NUM.not_same"
          :precision="row.bigUnit === row.smallUnit? 0 : 3"
          :controls="false"
        />
        <span class="unit-box">{{ row.bigUnit }}</span>
        <el-input-number
          v-model="row.smallUnitTransferAmount"
          :min="0"
          class="amount-box"
          size="small"
          step-strictly
          :step="0.001"
          :precision="3"
          :controls="false"
        />
        <span class="unit-box">{{ row.smallUnit }}</span>
      </template>
      <template #auxiliaryUnitAmount="{row}">
        <el-input-number
          v-model="row.auxiliaryUnitAmount"
          :min="0"
          size="small"
          :step="0.001"
          :precision="3"
          :disabled="!row.auxiliaryUnit"
          class="amount-box"
          step-strictly
          :controls="false"
        />
        <span class="unit-box">{{ row.auxiliaryUnit }}</span>
      </template>
      <template #action="{row}">
        <nh-button type="text" @click="handleDelete(row)">
          删除
        </nh-button>
      </template>
    </PlTable>
    <template #footer>
      <nh-button
        type="primary"
        @click="handleClose"
      >
        取 消
      </nh-button>
      <nh-button
        type="primary"
        :loading="loading.handleSubmit"
        @click="handleSubmit"
      >
        确 定
      </nh-button>
    </template>
  </nh-dialog>
  <add-details ref="addDetails" @submit="addNewGoods" />
</template>

<script>
import loadingMixin from '@thales/loading';
import { storehousePage } from '@/views/base/warehouse-about/warehouse-storehouse/api';
import { warehouseContainerPage } from '@/views/base/warehouse-about/container/api';
import TABLECOLUMN from './fileds';
import { stockTransferOrderEdit, stockTransferOrderDetail } from '../api';
import { STEPS_NUM, PRECISION_NUM } from '../constant';
import AddDetails from './components/AddDetails.vue';

export default {
  components: {
    AddDetails,
  },
  mixins: [loadingMixin],
  emits: ['submitSuccess'],
  data() {
    return {
      STEPS_NUM,
      PRECISION_NUM,
      TABLECOLUMN,
      stockOrederId: '',
      dialogVisible: false,
      tableData: [],
      loading: {
        remoteLocation: false,
        remoteContainerCode: false,
        handleSubmit: false,
      },
    };
  },
  methods: {
    handleClose() {
      this.dialogVisible = false;
    },
    init(id) {
      this.dialogVisible = true;
      this.stockTransferOrderDetail(id);
      this.stockOrederId = id;
    },
    async stockTransferOrderDetail(id) {
      this.tableData = await stockTransferOrderDetail({ transferOrderId: id });
    },
    handleAdd() {
      this.$refs.addDetails.init();
    },
    addNewGoods(goods) {
      const newGoods = {
        ...goods,
        stockId: goods.id,
        bigUnitStockAmountWithUnit: goods.totalAmountStr,
        smallUnitStockAmountWithUnit: '',
        bigUnitAvailableAmountWithUnit: goods.bigUnitAvailableAmount + goods.bigUnit,
        smallUnitAvailableAmountWithUnit: goods.smallUnitAvailableAmount + goods.smallUnit,
        sourceContainerCode: goods.containerCode,
        sourceLocationCode: goods.locationCode,
        goodsQualityDesc: goods.goodsQuality,
        auxiliaryUnitAmount: goods.auxiliaryAmount,
        targetLocationCode: null,
        targetContainerCode: null,
      };
      this.tableData.push({ ...newGoods });
    },
    handleDelete(row) {
      const index = this.tableData.indexOf(row);
      if (index !== -1) {
        this.tableData.splice(index, 1);
      }
    },
    handleBeforeClose() {
      this.tableData = [];
    },
    buildParams() {
      const data = this.tableData.map(({
        stockId,
        goodsId,
        conversionRate,
        targetLocationCode,
        targetContainerCode,
        bigUnitTransferAmount,
        smallUnitTransferAmount,
        auxiliaryUnitAmount,
        bigUnit,
        smallUnit,
      }) => {
        const reservedDigits = bigUnit === smallUnit
          ? this.PRECISION_NUM.the_same : this.PRECISION_NUM.not_same;
        return {
          stockId,
          goodsId,
          conversionRate,
          targetLocationCode,
          targetContainerCode,
          bigUnitTransferAmount: bigUnitTransferAmount ? bigUnitTransferAmount.toFixed(reservedDigits) : '',
          smallUnitTransferAmount: smallUnitTransferAmount ? smallUnitTransferAmount.toFixed(3) : '',
          auxiliaryUnitTransferAmount: auxiliaryUnitAmount ? auxiliaryUnitAmount.toFixed(3) : '',
        };
      });
      return data;
    },
    async handleSubmit() {
      const data = this.buildParams();
      await stockTransferOrderEdit({ details: data, id: this.stockOrederId });
      this.$message({ type: 'success', message: '移库单修改成功' });
      this.handleClose();
      this.$emit('submitSuccess');
    },
    async remoteLocation(query, row) {
      const result = await storehousePage({ storageLocationCode: query });
      const current = row;
      current.storehouseList = result.records.map(({ storageLocationCode }) => ({
        value: storageLocationCode,
        label: storageLocationCode,
      }));
    },
    async remoteContainerCode(query, row) {
      const result = await warehouseContainerPage({ containerCode: query });
      const current = row;
      current.containerCodeList = result.records.map(({ containerCode }) => ({
        value: containerCode,
        label: containerCode,
      }));
    },
  },
};
</script>
<style lang="scss" scoped>
.amount-box {
  width: 48px;
}

.unit-box {
  margin: 0 10px;
}
</style>
