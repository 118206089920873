import { FormType } from '@/constant/form';
import { DateTime } from '@/constant/tableConfig';
import {
  TRANSFER_TYPE, TRANSFER_STATUS, OPERATE_TYPE,
} from './constant';

function filterLabel(val, arr = []) {
  const filterArr = arr.find((item) => item.value === val);
  return filterArr ? filterArr.label : '';
}
export const TABLECOLUMN = [{
  type: 'selection',
  width: 50,
}, {
  label: '序号',
  type: 'index',
  width: 50,
}, {
  label: '移库单号',
  prop: 'transferOrderNo',
  minWidth: 180,
}, {
  label: '移库类型',
  prop: 'transferType',
  minWidth: 100,
  formatter: (row) => filterLabel(row.transferType, TRANSFER_TYPE),
}, {
  label: '作业方式',
  prop: 'operateType',
  minWidth: 100,
  formatter: (row) => filterLabel(row.operateType, OPERATE_TYPE),
}, {
  label: '状态',
  prop: 'status',
  minWidth: 80,
  formatter: (row) => filterLabel(row.status, TRANSFER_STATUS),
}, {
  label: '品项数',
  prop: 'itemCount',
  minWidth: 80,
}, {
  label: '总数量(零单位)',
  prop: 'totalCount',
  minWidth: 120,
}, {
  label: '创建人',
  prop: 'createUserName',
  minWidth: 100,
}, {
  label: '创建时间',
  prop: 'createTime',
  ...DateTime,
}, {
  label: '移库完成时间',
  prop: 'completedTime',
  ...DateTime,
}, {
  label: '操作',
  prop: 'action',
  fixed: 'right',
  width: 290,
},
];
export const serchFields = [{
  label: '移库单号',
  prop: 'orderNo',
  component: FormType.INPUT,
},
// 表中暂无此数据，此次版本（2021-12-09）暂时屏蔽，后期由产品确认
// {
//   label: '货主',
//   prop: 'shipperId',
// },
{
  label: '移库类型',
  prop: 'transferType',
  component: FormType.SELECT,
  options: TRANSFER_TYPE,
}, {
  label: '移库单状态',
  prop: 'status',
  component: FormType.SELECT,
  options: TRANSFER_STATUS,
}, {
  label: '源库位',
  prop: 'sourceLocationCode',
  component: FormType.INPUT,
}, {
  label: '目的库位',
  prop: 'targetLocationCode',
  component: FormType.INPUT,
}, {
  label: '货品编号',
  prop: 'goodsCode',
  component: FormType.INPUT,
  componentAttrs: {
    placeholder: '货主货品编码/货主编码',
  },
}, {
  label: '创建时间',
  prop: 'createTime',
  component: FormType.DATE_PICKER,
  componentAttrs: {
    type: 'daterange',
    default: [],
  },
}, {
  label: '创建人',
  prop: 'createUserName',
  component: FormType.INPUT,
}, {
  label: '下架开始时间',
  prop: 'downShelfStartTime',
  component: FormType.DATE_PICKER,
  componentAttrs: {
    type: 'daterange',
    default: [],
  },
}, {
  label: '下架完成时间',
  prop: 'downShelfEndTime',
  component: FormType.DATE_PICKER,
  componentAttrs: {
    type: 'daterange',
    default: [],
  },
},
// TODO:暂时无，后期会开放
// {
//   label: '下架人',
//   prop: 'operatorName',
//   component: FormType.INPUT,
// },
{
  label: '上架开始时间',
  prop: 'upperShelfStartTime',
  component: FormType.DATE_PICKER,
  componentAttrs: {
    type: 'daterange',
    default: [],
  },
}, {
  label: '上架完成时间',
  prop: 'upperShelfEndTime',
  component: FormType.DATE_PICKER,
  componentAttrs: {
    type: 'daterange',
    default: [],
  },
},
// TODO:暂时无，后期会开放
// {
//   label: '上架人',
//   prop: 'operatorName',
//   component: FormType.INPUT,
// },
];
