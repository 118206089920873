import { TaskType, TaskTypeText } from '@/constant/task';

export const TRANSFER_TYPE = [
  {
    value: 'ONE_CLICK',
    label: '一键移库',
  },
  {
    value: 'TALLY',
    label: '理货移库',
  },
  {
    value: 'DAILY',
    label: '日常补货',
  },
  {
    value: 'URGENT',
    label: '紧急补货',
  },
];

export const TRANSFER_STATUS = [
  {
    value: 'DRAFT',
    label: '草稿',
  },
  {
    value: 'TO_START',
    label: '未开始',
  },
  {
    value: 'TRANSFERRING',
    label: '移库中',
  },
  {
    value: 'COMPLETED',
    label: '已完成',
  },
  {
    value: 'CLOSED',
    label: '已关闭',
  },
];
export const TRANSFER_STATUS_EN = {
  DRAFT: 'DRAFT',
  TO_START: 'TO_START',
  TRANSFERRING: 'TRANSFERRING',
  COMPLETED: 'COMPLETED',
  CLOSED: 'CLOSED',
};
export const OPERATE_TYPE = [
  {
    value: 'PAPER',
    label: '纸单',
  },
  {
    value: 'PDA',
    label: 'PDA',
  },
  {
    value: 'AUTOMATIC',
    label: '自动化',
  },
];
export const OPERATE_TYPE_CN = {
  PAPER: '纸单',
  PDA: 'PDA',
  AUTOMATIC: '自动化',
};
export const OPERATE_TYPE_EN = {
  PAPER: 'PAPER',
  PDA: 'PDA',
  AUTOMATIC: 'AUTOMATIC',
};
export const STEPS_NUM = {
  the_same: 1,
  not_same: 0.001,
};

export const PRECISION_NUM = {
  the_same: 0,
  not_same: 3,
};
// 温层
export const TEMPERATURE_LAYER = {
  NORMAL: '常温',
  COLD: '冷冻',
  REFRIGERATE: '冷藏',
  CONSTANT: '恒温',
};

export const GOODS_STATUS = {
  TO_DOWN_SHELF: '待下架',
  IN_DOWN_SHELF: '下架中',
  TO_UPPER_SHELF: '待上架',
  IN_UPPER_SHELF: '上架中',
  COMPLETED: '移库完成',
};

export const TASK_TYPES = { ...TaskType };
export const TASK_TYPES_CN = { ...TaskTypeText };
export const SUBTASK_STATUS = {
  PREPARE: '准备中',
  DOING: '作业中',
  FINISHED: '已完结',
  CLOSE: '已关闭',
};
