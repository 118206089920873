<template>
  <pl-block>
    <PlForm
      v-model="searchQuery"
      mode="search"
      :fields="serchFields"
      @submit="handleQuery"
      @reset="handleQuery"
    >
    <!-- 表中暂无此数据，此次版本（2021-12-09）暂时屏蔽，后期由产品确认 -->
      <!-- <template #shipperId>
        <nh-shipper-selector
          v-model:shipperId="searchQuery.shipperId"
        />
      </template> -->
    </PlForm>
  </pl-block>
  <pl-block>
    <PlTable
      v-model:pagination="pagination"
      :loading="loading.getTableData"
      :data="tableData"
      :columns="TABLECOLUMN"
      :show-table-setting="true"
      @selection-change="handleSelectionChange"
      @update:pagination="handlePaginationChange"
    >
      <template #tableHeaderRight>
        <nh-button
          :track="{
            trackName: '在库管理/移库管理/[导入]'
          }"
          @click="handleInfoImport"
        >
          导入
        </nh-button>
        <!-- <nh-button
          type="primary"
          size="small"
          plain
          @click="handleExport"
        >
          导出
        </nh-button> -->
      </template>
      <template #transferOrderNo="{row}">
        <nh-link
          :to="{}"
          :text="row.transferOrderNo"
          @click="handleDetails(row)"
        />
      </template>
      <template #action="{row}">
        <nh-button
          v-if="row.status === TRANSFER_STATUS_EN.DRAFT"
          type="text"
          @click="handleEdit(row.id)"
        >
          编辑
        </nh-button>
        <nh-button
          v-if="row.status === TRANSFER_STATUS_EN.DRAFT"
          type="text"
          :loading="loading.handleRefer"
          @click="handleRefer(row.id)"
        >
          提交
        </nh-button>
        <nh-button
          v-if="row.status === TRANSFER_STATUS_EN.DRAFT"
          type="text"
          :loading="loading.handleDelete"
          @click="handleDelete(row.id)"
        >
          删除
        </nh-button>
        <nh-button
          v-if="row.status === TRANSFER_STATUS_EN.DRAFT
            || row.status === TRANSFER_STATUS_EN.TO_START
            || row.status === TRANSFER_STATUS_EN.TRANSFERRING"
          type="text"
          @click="handlePrint(row.id)"
        >
          打印
        </nh-button>
        <nh-button
          v-if="row.status === TRANSFER_STATUS_EN.TO_START
            && row.operateType === OPERATE_TYPE_EN.PAPER"
          type="text"
          :loading="loading.handleSubmit"
          @click="handleSubmit(row.id)"
        >
          确认
        </nh-button>
        <nh-button
          v-if="row.status === TRANSFER_STATUS_EN.TO_START
            || row.status === TRANSFER_STATUS_EN.DRAFT"
          type="text"
          @click="handleChangeJobWay(row)"
        >
          更改作业方式
        </nh-button>
        <nh-button
          v-if="row.status === TRANSFER_STATUS_EN.TO_START"
          type="text"
          @click="handleClose(row.id)"
        >
          关闭
        </nh-button>
      </template>
    </PlTable>
  </pl-block>
  <library-transfer-edit ref="libraryTransferEdit" @submitSuccess="handleQuery" />
  <operate-type-change ref="operateTypeChange" @submit="handleQuery" />
  <nh-data-import
    ref="DataImport"
    :err-msg-list="errMsgList"
    :is-upload="isUpload"
    @upload="warehouseGoodsImport"
    @downLoadTemplate="handleDownTemplate"
    @submitSuccess="handleGoodsImportSubmitSuccess"
    @cancel="handleCancel"
    @clearAll="handleGoodsImportClearAll"
  />
  <library-details ref="libraryDetailsDialog" />
</template>
<script>
import loadingMixin from '@thales/loading';
import InLibrary from '@/constant/pageNames/inLibrary';
import { TABLECOLUMN, serchFields } from './fileds';
import {
  stockTransferOrderPage,
  stockTransferOrderDelete,
  operateTypeRefer,
  stockTransferOrderClose,
  stockTransferOrderConfirm,
  downLoadTemplate,
  transferOrderImportCheck,
  transferOrderGenerate,
} from './api';
import { TRANSFER_STATUS_EN, OPERATE_TYPE_EN } from './constant';
import LibraryTransferEdit from './library-transfer-edit/index.vue';
import OperateTypeChange from './operate-type-change/index.vue';
import LibraryDetails from './library-transfer-details/index.vue';

export default {
  name: InLibrary.LIBRARY_TRANSFER_MANAGEMENT,
  components: {
    LibraryTransferEdit,
    OperateTypeChange,
    LibraryDetails,
  },
  mixins: [loadingMixin],
  data() {
    return {
      serchFields,
      TABLECOLUMN,
      TRANSFER_STATUS_EN,
      OPERATE_TYPE_EN,
      tableData: [],
      searchQuery: {
        shipperId: null,
      },
      loading: {
        getTableData: false,
        handleRefer: false,
        handleDelete: false,
        handleSubmit: false,
      },
      selectIds: [],
      isUpload: false,
      errMsgList: [],
      alltransferOrder: [],
    };
  },
  activated() {
    this.getTableData();
  },
  methods: {
    handleQuery() {
      this.pagination.page = 1;
      this.getTableData();
    },
    async getTableData() {
      this.selectIds = [];
      const params = this.buildParams();
      const pagination = {
        page: this.pagination.page,
        size: this.pagination.size,
      };
      const resp = await stockTransferOrderPage(pagination, params);
      this.tableData = resp.records;
      this.pagination.total = resp.total;
    },
    handleSelectionChange(selection) {
      this.selectIds = selection.map((item) => item.id);
    },
    buildParams() {
      const [createTimeStart,
        createTimeEnd] = this.searchQuery.createTime || [];
      const [downShelfStartTimeStart,
        downShelfStartTimeEnd] = this.searchQuery.downShelfStartTime || [];
      const [downShelfEndTimeStart,
        downShelfEndTimeEnd] = this.searchQuery.downShelfEndTime || [];
      const [upperShelfStartTimeStart,
        upperShelfStartTimeEnd] = this.searchQuery.upperShelfStartTime || [];
      const [upperShelfEndTimeStart,
        upperShelfEndTimeEnd] = this.searchQuery.upperShelfEndTime || [];
      return {
        ...this.searchQuery,
        createTimeStart,
        createTimeEnd,
        downShelfStartTimeStart,
        downShelfStartTimeEnd,
        downShelfEndTimeStart,
        downShelfEndTimeEnd,
        upperShelfStartTimeStart,
        upperShelfStartTimeEnd,
        upperShelfEndTimeStart,
        upperShelfEndTimeEnd,
      };
    },
    handlePaginationChange() {
      this.getTableData();
    },
    async handleExport() {
      // const data = this.buildParams();
      // if (this.selectIds.length > 0) {
      //   data.selectIds = this.selectIds;
      // }
      // shipperGoodsStockExport(data);
    },
    handleEdit(id) {
      this.$refs.libraryTransferEdit.init(id);
    },
    async handleRefer(id) {
      await operateTypeRefer({ id });
      this.$message({ type: 'success', message: '提交成功' });
      this.getTableData();
    },
    async handleDelete(id) {
      await stockTransferOrderDelete({ id });
      this.$message({ type: 'success', message: '删除成功' });
      this.getTableData();
    },
    async handleClose(id) {
      await stockTransferOrderClose({ id });
      this.$message({ type: 'success', message: '关闭成功' });
      this.getTableData();
    },
    handleChangeJobWay(row) {
      this.$refs.operateTypeChange.init(row);
    },
    handleInfoImport() {
      this.$refs.DataImport.showDialog();
    },
    handlePrint(id) {
      const routeUrl = this.$router.resolve({
        path: '/print/transfer-list',
        query: { transferOrderId: id },
      });
      window.open(routeUrl.href, '_blank');
    },
    async handleSubmit(id) {
      await stockTransferOrderConfirm({ id });
      this.$message({ type: 'success', message: '确认完成' });
      this.getTableData();
    },
    async warehouseGoodsImport(fd) {
      const { data, errorMsg = [] } = await transferOrderImportCheck(fd);
      this.isUpload = true;
      this.errMsgList = [...this.errMsgList, ...errorMsg.map((item) => ({ errorMsg: item }))];
      this.alltransferOrder = [...this.alltransferOrder, ...data];
    },
    async handleDownTemplate() {
      await downLoadTemplate({ templateId: 'transfer_detail_import_template' });
    },
    async handleGoodsImportSubmitSuccess() {
      if (this.errMsgList && this.errMsgList.length <= 0) {
        await this.transferOrderSave();
      } else {
        this.$message({
          message: '请根据错误信息修改后再上传',
          type: 'warning',
        });
      }
    },
    async  transferOrderSave() {
      const data = {
        details: [...this.alltransferOrder],
      };
      await transferOrderGenerate(data);
      this.$message({
        message: '保存成功',
        type: 'success',
      });
      this.handleCancel();
      this.getTableData();
    },
    handleCancel() {
      this.handleGoodsImportClearAll();
      this.$refs.DataImport.handleClose();
    },
    handleGoodsImportClearAll() {
      this.isUpload = false;
      this.alltransferOrder = [];
      this.errMsgList = [];
    },
    handleDetails(row) {
      this.$refs.libraryDetailsDialog.open(row);
    },
  },
};
</script>
<style lang="scss" scoped>
.batch-field {
  text-indent: 12px;
  padding: 4px 0;
}
</style>
