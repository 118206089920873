import { FormType } from '@/constant/form';

export const selectProp = ['shipperId', 'shipperGoodsCode', 'locationCode', 'batchNo', 'goodsId', 'storehouseId'];
export default [
  {
    label: '所属货主：',
    prop: 'shipperId',
    colAttrs: {
      span: 8,
    },
  },
  {
    label: '货主货品编码：',
    prop: 'shipperGoodsCode',
    colAttrs: {
      span: 8,
    },
  },
  {
    label: '货品名称：',
    prop: 'goodsName',
    colAttrs: {
      span: 8,
    },
    component: FormType.INPUT,
    componentAttrs: {
      disabled: true,
    },
  },
  {
    label: '库位：',
    prop: 'locationCode',
    colAttrs: {
      span: 8,
    },
  },
  {
    label: '批次号：',
    prop: 'batchNo',
    colAttrs: {
      span: 8,
    },
  },
  {
    label: '托盘码：',
    prop: 'containerCode',
    colAttrs: {
      span: 8,
    },
    component: FormType.INPUT,
    componentAttrs: {
      disabled: true,
    },
  },
  {
    label: 'LPN：',
    prop: 'lpn',
    colAttrs: {
      span: 8,
    },
    component: FormType.INPUT,
    componentAttrs: {
      disabled: true,
    },
  },
  {
    label: '可移库数量：',
    prop: 'availableAmount',
    colAttrs: {
      span: 8,
    },
  },
  {
    label: '温层：',
    prop: 'temperatureLayerName',
    colAttrs: {
      span: 8,
    },
    component: FormType.INPUT,
    componentAttrs: {
      disabled: true,
    },
  },
];
