<template>
  <nh-dialog
    v-model="dialogVisible"
    title="更改作业方式"
    :track="{
      name_zh: `在库管理/移库管理/弹窗-更改作业方式`,
      trackName: $route.path
    }"
    @close="handleBeforeClose"
  >
    <PlForm
      v-model="form"
      mode="normal"
      :fields="formFileds"
      :form-props="{rules:rules}"
      :collapse="false"
      submit-btn-text="确 定"
      reset-btn-text="取 消"
      @submit="handleSubmit"
      @reset="handleClose"
    >
      <template #oldOperateType>
        <span>{{ OPERATE_TYPE_CN[form.oldOperateType] }}</span>
      </template>
    </PlForm>
  </nh-dialog>
</template>

<script>
import { OPERATE_TYPE_CN, OPERATE_TYPE } from '../constant';
import { operateTypeChange } from '../api';
import formFileds from './fileds';

export default {
  emits: ['submit'],
  data() {
    return {
      OPERATE_TYPE_CN,
      OPERATE_TYPE,
      formFileds,
      dialogVisible: false,
      form: {},
    };
  },
  created() {
  },
  mounted() {
  },
  methods: {
    handleClose() {
      this.dialogVisible = false;
    },
    init(row) {
      const { id, operateType } = row;
      this.form = {
        id,
        oldOperateType: operateType,
      };
      this.dialogVisible = true;
    },
    handleBeforeClose() {
      this.form = {};
    },
    async handleSubmit() {
      if (!this.form.operateType) {
        this.$message({ type: 'warning', message: '请先选择作业方式' });
        return;
      }
      await operateTypeChange(this.form);
      this.$message({ type: 'success', message: '移库单生成成功' });
      this.handleClose();
      this.$emit('submit');
    },
  },
};
</script>
